import React, { useState, useEffect,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { CiSearch } from "react-icons/ci";
import { PiHandbagSimpleLight } from "react-icons/pi";
import { RxHamburgerMenu } from "react-icons/rx";
import { GoHeart } from "react-icons/go";
import { PiUserCircle } from "react-icons/pi";
import { SlHome } from "react-icons/sl";
import { TfiUser } from "react-icons/tfi";
import { SlUserFemale } from "react-icons/sl";
import { PiUser } from "react-icons/pi";
import { PiBaseballCapThin } from "react-icons/pi";
import { TbBrandBlogger } from "react-icons/tb";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import Carousel from 'react-bootstrap/Carousel';
import logo from '../../images/vlogo.png'
import {Link,useNavigate} from 'react-router-dom'
import { BsBox } from "react-icons/bs";
import './header.css'
import {getAllBanner} from '../../features/banner/bannerSlice'
import {getAllCollection} from '../../features/collection/collectionSlice'
import {words} from './words'
const delayExecution = (mls) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve("ok"), mls);
  });
};

const Header = () => {
  const [index, setIndex] = useState(0);
  const searchInputRef = useRef(null); // Create a ref for the search input
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const placeholderText = ["Search Shirts", "Search Loafers", "Search Dresses"];
  const [state, setState] = useState("");
  const [search,setSearch] =useState("none")
  const [svalue,setSvalue]=useState("")
  const [isHead2Open, setIsHead2Open] = useState(false); // Define isHead2Open state variable

const dispatch=useDispatch()
useEffect(()=>{
  dispatch(getAllCollection())
  dispatch(getAllBanner())
},[dispatch])
useEffect(() => {
  if (search === "block" && searchInputRef.current) {
    searchInputRef?.current?.focus(); // Focus on the search input after it's displayed
  }
}, [search]);
  const openSearch=()=>{
    setSearch("block")
    setScrolled(true);
    

  }
  const closeSearch=()=>{
    setSearch("none")
    setScrolled(false);


  }
  const cart=JSON.parse(localStorage.getItem("cart"))
  const wishlist=JSON.parse(localStorage.getItem("wishlist"))
const navigate=useNavigate()
  const changePlaceholder = async () => {
    for (let i = 0; ; i = (i + 1) % placeholderText.length) {
      await delayExecution(3000);
      setState(placeholderText[i]);
    }  
  };


useEffect(()=>{
  changePlaceholder()
},[])
const [results, setResults] = useState([]);
useEffect(() => {
  if (svalue?.length > 1) {
    // Filter the words that contain the input value
    const filteredWords = words
      .filter((item) => item?.name?.toLowerCase()?.includes(svalue?.toLowerCase()))
      .slice(0, 5);

    setResults(filteredWords); // Store the filtered words in state
  } else {
    setResults([]); // Clear results when input is less than 2 characters
  }
}, [svalue]);
  const hamClick=()=>{
    document.getElementById("head2").style.left=0
    setIsHead2Open(true)
    document?.body?.classList?.add('no-scroll'); // Add class to prevent scrolling

  }
  const closeClick=()=>{
    document.getElementById("head2").style.left="-100%"
    setIsHead2Open(false)
    document?.body?.classList?.remove('no-scroll'); 

  }
const authState=useSelector(state=>state?.auth)
const loginOpen=()=>{
  if(authState?.user===null){
    navigate('/login')
  }
  else{
    navigate("/profile")
  }
  
}

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window?.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window?.addEventListener('scroll', handleScroll);

    return () => {
      window?.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);
  const handleKeyDown = (event) => {
    if (event?.key === 'Enter') {
      localStorage?.setItem("search",JSON.stringify({
        mysearch:svalue
        
      }))
     
        navigate(`/products`)
        setSearch("none")
        setSvalue("")
    setScrolled(false);
    }
    
};
const searchValueClick = (value) => {
    localStorage?.setItem("search",JSON.stringify({
      mysearch:value
      
    }))
   
      navigate(`/products`)
      setSearch("none")
      setSvalue("")
  setScrolled(false);
  
  
};


  return (
    <>
    <div className="main-head">
    <Carousel activeIndex={index} onSelect={handleSelect} controls={false} indicators={false}>
      <Carousel.Item interval={3500}>
        <p style={{marginTop:'10px'}}>Get 10% Off + Free Shipping on Prepaid Orders</p>
      </Carousel.Item>
      <Carousel.Item interval={3500}>
      <p style={{marginTop:'10px'}}>    Use code "SAVE5" and get 5% off</p>
      </Carousel.Item>
  
    </Carousel>


    </div>
    <div className={`header ${scrolled ? 'scrolled' : ''}`} style={{backgroundColor:search==="flex"?'white':''}}>
    {isHead2Open && <div className="overlay" onClick={()=>closeClick()}></div>}
      <div className="headerdiv">
        <div className='head1'>
            <Link to="/home"><img src={logo} alt="" style={{width:'140px'}}/></Link>
        </div>
        <div className='head2' style={{textAlign:'center'}}>
            <ul>
            <li><Link to="/home">Home</Link></li>
              <li><Link to="/men">Mens</Link></li>
              <li><Link to="/women">Womens</Link></li>
              <li><Link to="/kids">Kids</Link></li>
              <li><Link to="/accessories">Accessories</Link></li>
            </ul>
        </div>
        <div className='head3' style={{textAlign:'right'}}>
          <ul>
          <li onClick={openSearch}><CiSearch className='icon head-icons'/></li>
            <li className='cart-len'>{
              wishlist && wishlist?.length>=1?<p className='cart-length'>{wishlist?.length}</p>
              :
              ""
            }<Link to="/wishlist"><GoHeart className='back-ic head-icons'/></Link></li>
            <li className='cart-len'>{
              cart && cart?.length>=1?<p className='cart-length'>{cart?.length}</p>
              :
              ""
            }<Link to="/cart"><PiHandbagSimpleLight className='back-ic head-icons'/></Link></li>
            <li><Link to="/orders" className='o-btn'>Orders</Link></li>
          </ul>
              

        </div>
       
      </div>
      
      <div className="header1">
      <div className="headerdiv1">
      <div className="hamburger">
        <RxHamburgerMenu className='icon' id="hamburger" onClick={()=>hamClick()} />
      </div>
        <div className='head1'>
            <Link to="/home"><img src={logo} alt=""/></Link>
        </div>
        
        <div className='head3' style={{textAlign:'right'}}>
          <ul>
            <li onClick={openSearch}><CiSearch className='mob-s'/></li>
            <Link to="/cart">
            <li className="cart-len">{
              cart && cart?.length>=1?<p className='cart-length'>{cart?.length}</p>
              :
              ""
            }<PiHandbagSimpleLight className='cart-i back-ic'/></li></Link>


          </ul>
              

        </div>
      </div>
      <div className='head2' style={{textAlign:'center'}} id='head2'>
      <div className='head1'>
            <Link to="/home"><img src={logo} alt=""/></Link>
        </div>
            <IoCloseOutline className='cancel-icon' id="close-ham" onClick={()=>closeClick()}/>
            <ul>
              
              
              <li onClick={()=>closeClick()}><Link to="/home"><SlHome className='menu-icon'/> Home</Link></li>
              <li onClick={()=>closeClick()}><Link to="/men"><TfiUser className='menu-icon'/> Mens</Link></li>
              <li onClick={()=>closeClick()}><Link to="/women"><SlUserFemale className='menu-icon'/> Womens</Link></li>
              <li onClick={()=>closeClick()}><Link to="/kids"><PiUser className='menu-icon'/> Kids</Link></li>
              <li onClick={()=>closeClick()}><Link to="/accessories"><PiBaseballCapThin className='menu-icon'/> Accessories</Link></li>
              <li onClick={()=>closeClick()}><Link to="/blogs"><TbBrandBlogger className='menu-icon'/> Blogs</Link></li>
              <li onClick={()=>closeClick()}><Link to="/about"><IoIosInformationCircleOutline className='menu-icon'/> About</Link></li>
              <li onClick={()=>closeClick()}><Link to="/wishlist"><GoHeart className='menu-icon'/> Wishlist ( {wishlist?.length} )</Link></li>
              <li onClick={()=>closeClick()}><Link to="/orders"><BsBox className='menu-icon'/> Orders</Link></li>
              
            </ul>
        </div>
      </div>
      <div className="search" style={{display:search}}>
      <div className="input-div">
      <input type="text" ref={searchInputRef} placeholder={state} value={svalue} onChange={(e)=>setSvalue(e.target.value)} onKeyDown={handleKeyDown} />
      <p onClick={(e)=>closeSearch()}><IoCloseOutline style={{cursor:'pointer'}}  /></p>
      </div>
      <div className="suggestions">
        <p>Search Suggestions</p>
      <ul>
        {results?.map((item, index) => (
          <li key={index} onClick={(e)=>searchValueClick(item?.value)}><CiSearch className='icos'/>{item.name}</li>
        ))}
      </ul>
      </div>
      <div className="collection-names">
      <p>Trending Collections</p>
      </div>
      
        </div>

    </div>
    </>
  )
}

export default Header
