import './home.css'
import React,{useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useState } from 'react'
const Home = () => {
  useEffect(() => {
    const canonicalUrl =`https://voguemine.com/`;
    let canonicalLink = document?.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      canonicalLink.setAttribute('href', canonicalUrl);
    } else {
      canonicalLink = document?.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      canonicalLink.setAttribute('href', canonicalUrl);
      document.head.appendChild(canonicalLink);
    }
}, []);
const bannerState=useSelector((state)=>state?.banner?.banner)
useEffect(() => {
    document.title = "Vogue Mine | Online Shopping for Women, Men, Kids Lifestyle";
    document.querySelector('meta[name="description"]').setAttribute('content', "Buy Premium quality products from Top Fashion Brands for Men, Women, Kids Online in India");
}, []);

const modifyCloudinaryUrl = (url) => {
  const urlParts = url?.split('/upload/');
  return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
};
const [anime,setAnime]=useState(true)
useEffect(()=>{
setTimeout(()=>{
  setAnime(false)
},4000)
},[])
return (
    <div className='home'>
      <div className="my-anime"style={{display:anime===true?"flex":"none"}} >
        <p>WELCOME TO</p>
        <h1 data-text="VOGUEMINE">VOGUEMINE</h1>
      </div>
      <div className="video-box" style={{backgroundImage:`url("${modifyCloudinaryUrl(bannerState && bannerState[45]?.images[0]?.url)}")`}}>
</div>
<div className="video-box1" style={{backgroundImage:`url("${modifyCloudinaryUrl(bannerState && bannerState[45]?.images[1]?.url)}")`}}>
</div>
<Link to="/home" style={{textDecoration:'none'}}>SHOP NOW</Link>
    </div>
  )
}

export default Home