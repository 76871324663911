import './App.css';
import React,{useEffect,useState} from 'react';
import Home from './pages/home/Home'
import {BrowserRouter as Router, Routes, Route,Navigate} from 'react-router-dom'
import HomeMain from './pages/homeMain/HomeMain'
import Header from '../src/components/header/Header'
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/footer/Footer';
import About from './pages/categoryPage/About';
import Men from './pages/categoryPage/Men';
import Women from './pages/categoryPage/Women';
import Kids from './pages/categoryPage/Kids';
import Accessories from './pages/categoryPage/Accessories';
import Cart from './pages/cart/Cart';
import SingleProduct from './pages/singleproduct/SingleProduct';
import Products from './pages/products/Products';
import Wishlist from './pages/Wishlist';
import Blogs from './pages/blogs/Blogs';
import SingleBlog from './pages/blogs/SingleBlog';
import Checkout from './pages/checkout/Checkout';
import Profile from './pages/profile/Profile';
import ScrollToTop from './components/ScrollToTop';
import RedirectUri from './pages/Testing';
import Policies from './pages/policies/Policies';
import Contact from './pages/contact/Contact';
import Newlogin from './pages/login/Newlogin';
import Orders from './pages/orders/Orders'
import Error from './pages/error/Error'
import { IoMdClose } from "react-icons/io";

function App() {

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const popupShown = localStorage.getItem("popupShown");

    // Show the popup if it hasn't been shown yet
    if (!popupShown) {
      setShowPopup(true);
    }

    // Listen for beforeunload event to reset the popup flag when the user leaves
    const resetPopup = () => {
      localStorage.removeItem("popupShown");
    };

    window.addEventListener("beforeunload", resetPopup);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener("beforeunload", resetPopup);
    };
  }, []);

  // Function to close the popup and store a flag in localStorage
  const handleClosePopup = () => {
    setShowPopup(false);
    localStorage.setItem("popupShown", "true");
  };
  
  return (
    <>
    <Router basename='/'>
    <div className="my-popup" style={{display:showPopup?"flex":"none"}}>
      <div className="pop-content">
        <IoMdClose className='pop-close' onClick={handleClosePopup}/>
      <img src="https://res.cloudinary.com/dqh6bd766/image/upload/v1729256326/banner_48_ttd9gh.jpg" alt="" />
<div className="content">
  <h2>🎉 FESTIVE SALE ALERT! 🎉</h2>
  <p className='content1'>Unlock More Savings with Every Purchase!</p>
  <ul>
    <li>🛍️ Buy 1 product – <span>Get 10% OFF!</span></li>
    <li>🛍️ Buy 2 product – <span>Get 15% OFF!</span></li>
    <li>🛍️ Buy 3 product – <span>Get 20% OFF!</span></li>
    <li style={{color:"red"}}>🛍️ Only 10% OFF on Luggage Bags</li>
  </ul>
  <p className='content2'>Hurry up! Shop your favorites and save BIG on every purchase.💥</p>

</div>
      </div>
    </div>
    <Header/>
    <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/login' element={<Newlogin/>}/>
        <Route path='/home' element={<HomeMain/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/men' element={<Men/>}/>
        <Route path='/women' element={<Women/>}/>
        <Route path='/kids' element={<Kids/>}/>
        <Route path='/accessories' element={<Accessories/>}/>
        <Route path='/cart' element={<Cart/>}/>
        <Route path="/products" element={<Products/>}/>
        <Route path="/collections/:handle" element={<Products/>}/>
        <Route path="/products/:handle" element={<SingleProduct/>}/>
        <Route path="/wishlist" element={<Wishlist/>}/>
        <Route path='/blogs' element={<Blogs/>}/>
        <Route path="/blogs/news/:handle" element={<SingleBlog/>}/>
        <Route path="checkout" element={<Checkout/>}/>
        <Route path='/profile' element={<Profile/>}/>
        <Route path='/success' element={<RedirectUri/>}/>
        <Route path='/pages/shipping-policy' element={<Policies/>}/>
        <Route path='/pages/refund-and-return-policy' element={<Policies/>}/>
        <Route path='/pages/terms-of-service' element={<Policies/>}/>
        <Route path='/pages/privacy-policy' element={<Policies/>}/>
        <Route path='/pages/contact' element={<Contact/>}/>
        <Route path='/orders' element={<Orders/>}/>
        <Route path='*' element={<Error/>}/>
        <Route path='/404' element={<Error/>}/>
      </Routes>
      <Footer/>
    </Router>
    </>
  );
}

export default App;
