export const words=[
    {name:"shirt",value:"Shirt"},
    {name:"shirt",value:"Shirt"},
    {name:"shirt",value:"Shirt"},
    {name:"shirt",value:"Shirt"},
    {name:"shirt",value:"Shirt"},
    {name:"jeans",value:"Jeans"},
    {name:"tshirt",value:"Tshirt"},
    {name:"cap",value:"cap"},
    {name:"belt",value:"Louis Vuitton"},
    {name:"Louis Vuitton",value:"Louis Vuitton"}

]