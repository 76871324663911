import React, { useEffect, useState } from 'react';
import { createAnOrder } from '../features/user/userSlice';
import { useDispatch } from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
const RedirectUri = () => {
 const order=JSON.parse(localStorage.getItem("recentOrder"))
 const payUorder=JSON.parse(localStorage.getItem("payUOrder"))
const dispatch=useDispatch()
const navigate=useNavigate()
const addProductToOrderLocalStorage = (product) => {
  const existingOrder = JSON.parse(localStorage.getItem("orders")) || [];
  const updatedOrder = [...existingOrder, product];
  localStorage.setItem("orders", JSON.stringify(updatedOrder));
};

 const [paymentStatus, setPaymentStatus] = useState(null);
 const location = useLocation();
 useEffect(() => {
     const params = new URLSearchParams(location.search);
     const status = params.get('status');
     if (status === 'success') {
         setPaymentStatus('SUCCESS');
     } else if (status === 'failed') {
         setPaymentStatus('FAILED');
     }
     else if (status === 'success1') {
      setPaymentStatus('SUCCESS1');
  }
 }, [location.search]);


useEffect(()=>{
  if(paymentStatus==="SUCCESS1"){
    dispatch(createAnOrder(order))
    setTimeout(()=>{
      localStorage.removeItem('cart')
      addProductToOrderLocalStorage(order)
      localStorage.removeItem("recentOrder")
    },200)
  }
  else if(paymentStatus==="SUCCESS"){
    dispatch(createAnOrder(payUorder))
    setTimeout(()=>{
      localStorage.removeItem('cart')
      addProductToOrderLocalStorage(payUorder)
      localStorage.removeItem("payUOrder")
    },200)
  }
},[dispatch,paymentStatus])



  return (
    <>
    {
      paymentStatus==="SUCCESS"?<div className='margin-section' style={{ marginTop: '150px',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column' }}>
      <p style={{fontSize:'25px',fontWeight:'500',marginBottom:"20px"}}>Hello, {payUorder?.shippingInfo?.firstname}</p>
      <p style={{color:'green',fontSize:'19px',marginBottom:'20px'}}>Thank you for your trust in VogueMine! Your order has been processed and is on its journey to you.</p>
      <p style={{color:'green',fontSize:'19px',marginBottom:'20px'}}>Order Details will be send through Whatsapp soon.</p>
      <div>
      <button style={{color:'white',backgroundColor:'black',fontWeight:500,marginRight:'15px',border:'none',padding:'8px 15px'}} onClick={()=>navigate("/home")}>Continue Shopping</button>
      <button style={{color:'white',backgroundColor:'black',fontWeight:500,border:'none',padding:'8px 15px'}} onClick={()=>navigate("/orders")}>Track order</button>

      </div>
    </div>
    :paymentStatus==="SUCCESS1"?
    <div className='margin-section' style={{ marginTop: '150px',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column' }}>
      <p style={{fontSize:'25px',fontWeight:'500',marginBottom:"20px"}}>Hello, {order?.shippingInfo?.firstname}</p>
      <p style={{color:'green',fontSize:'19px',marginBottom:'20px'}}>Thank you for your trust in VogueMine! Your order has been processed and is on its journey to you.</p>
      <p style={{color:'green',fontSize:'19px',marginBottom:'20px'}}>Order Details will be send through Whatsapp soon.</p>

      <div>
      <button style={{color:'white',backgroundColor:'black',fontWeight:500,marginRight:'15px',border:'none',padding:'8px 15px'}} onClick={()=>navigate("/home")}>Continue Shopping</button>
      <button style={{color:'white',backgroundColor:'black',fontWeight:500,border:'none',padding:'8px 15px'}} onClick={()=>navigate("/orders")}>Track order</button>
      </div>
    </div>
    :paymentStatus==="FAILED"?
    <div className='margin-section' style={{ marginTop: '150px',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column' }}>
      <p style={{fontSize:'25px',fontWeight:'500',marginBottom:"20px"}}>Hello, {order?.shippingInfo?.firstname}</p>
      <p style={{color:'red',fontSize:'19px',marginBottom:'20px'}}>Sorry, We are unable to process your order right now, please try again!</p>
      <div>
      <button style={{color:'white',backgroundColor:'black',fontWeight:500,marginRight:'15px',border:'none',padding:'8px 15px'}} onClick={()=>navigate("/home")}>Continue Shopping</button>
      </div>
    </div>
    :
    <div className='margin-section' style={{ marginTop: '150px',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column' }}>
      <p style={{fontSize:'25px',fontWeight:'500',marginBottom:"20px"}}>Hello, {order?.shippingInfo?.firstname}</p>
      <p style={{color:'green',fontSize:'19px',marginBottom:'20px'}}>Thank you for your trust in VogueMine!</p>
      <div>
      <button style={{color:'white',backgroundColor:'black',fontWeight:500,marginRight:'15px',border:'none',padding:'8px 15px'}} onClick={()=>navigate("/home")}>Continue Shopping</button>
      </div>
    </div>


    }
    </>
    
  );
};

export default RedirectUri;
